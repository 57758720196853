<template>
    <div class="page" ref="home">
        <HeadingSection ref="HeadingSection" :seoBrand="null" :seoModel="null" :seoProvince="null" />
        <v-fab-transition>
            <v-btn
                id="return_to_search_button"
                title="Realiza otra búsqueda"
                alt="Realiza otra búsqueda"
                class="floating-action-button hidden"
                @click="scrollTo()"
                elevation="8"
                color="#248277"
                icon
                rounded
                x-large
            >
                <v-icon color="#FFFFFF">mdi-home-search</v-icon>
            </v-btn>
        </v-fab-transition>
        <div class="page-home">
            <div class="container text-left" id="last_added">
                <div class="row d-flex justify-content-left p-1 results-area" id="results_area">
                    <template v-if="(typeof ads.items == 'undefined' || ads.items.length == 0) && ads.loading">
                        <template v-if="ads.initialSearch">
                            <h2>{{ $t('second_hand_car_title') }}</h2>
                        </template>
                        <template v-else>
                            <h2>{{ $t('found_for_you') }}</h2>
                        </template>
                        <SkeletonAdCard v-for="i in 20" :key="i" />
                    </template>
                    <template v-else-if="(typeof ads.items == 'undefined' || ads.items.length == 0) && ads.errorFlag">
                        <div class="landing-picture row d-flex justify-content-center">
                            <div class="col-8 col-md-6 col-lg-4 mb-4">
                                <img
                                    src="@/assets/images/undraw_server_down_s-4-lk.svg"
                                    alt="Ha habido un error en el servicio"
                                />
                            </div>
                            <div class="col-12 col-md-6 mb-4 align-left">
                                <p class="unimportant-title">{{ $t('oops') }}</p>
                                <p v-html="$t('error_explanation')"></p>
                            </div>
                        </div>
                    </template>
                    <template v-else-if="typeof ads.items == 'undefined' || ads.items.length == 0">
                        <div class="landing-picture row d-flex justify-content-center">
                            <div class="col-8 col-md-6 col-lg-4 mb-4">
                                <img
                                    src="@/assets/images/undraw_feeling_blue_-4-b7q.svg"
                                    alt="No hay resultados para esta búsqueda"
                                />
                            </div>
                            <div class="col-12 col-md-6 mb-4 align-left">
                                <p class="unimportant-title">{{ $t('sorry') }}</p>
                                <p v-html="$t('no_results_explanation')"></p>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <template v-if="ads.initialSearch">
                            <h2>{{ $t('second_hand_car_title') }}</h2>
                            <p>{{ $t('second_hand_car_title_secondary') }}</p>
                        </template>
                        <template v-else>
                            <h2>{{ $t('found_for_you') }}</h2>
                        </template>
                        <AdCard v-for="ad in ads.items" :key="ad.id" :card="ad" :isFavourite="isFavourite(ad)" />
                        <template v-if="!ads.initialSearch && ads.loading">
                            <div class="mx-auto col-12 mb-5">
                                <v-progress-circular
                                    indeterminate
                                    color="#248277"
                                    class="mx-auto"
                                ></v-progress-circular>
                            </div>
                        </template>
                    </template>
                </div>
            </div>
            <template v-if="ads.initialSearch || ads.lastPage">
                <div class="container-fluid">
                    <div class="container text-left p-0" id="most_searched_brands">
                        <div class="row gy-3 pt-2 pb-4 justify-content-around" id="most_searched_brands_results_area">
                            <h2>{{ $t('main_brands_title') }}</h2>
                            <p>{{ $t('main_brands_title_secondary') }}</p>
                            <BrandCard v-for="brand in brands" :key="brand.id" :brand="brand" />
                        </div>
                    </div>
                </div>
                <div class="container-fluid pt-4 mt-5 pb-4 mb-5 other-model">
                    <div class="container text-left">
                        <div class="row d-flex justify-content-left p-1" id="other_models_links">
                            <h2>{{ $t('main_models_title') }}</h2>
                            <p>{{ $t('main_models_title_secondary') }}</p>
                            <div
                                class="col-12 col-lg-6 col-xxl-4 pb-2"
                                v-for="modelSection in modelSections"
                                :key="modelSection.model"
                            >
                                <a
                                    :href="`/coches-segunda-mano/${modelSection.makeSlug}/${modelSection.modelSlug}`"
                                    rel="dofollow"
                                >
                                    <b>{{ modelSection.make }} {{ modelSection.model }}</b>
                                    <img
                                        class="icon"
                                        src="@/assets/icons/right-arrow.svg"
                                        alt="Icono de flecha hacia adelante"
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <InfoSection ref="InfoSection" key="InfoSection" />
            </template>
        </div>
        <Footer />
    </div>
</template>
<script>
import { getFilterValues } from '@/application/getFilterValues/getFilterValues';
import AdCard from '@/components/cards/AdCard.vue';
import BrandCard from '@/components/cards/BrandCard.vue';
import SectionCard from '@/components/cards/SectionCard.vue';
import SkeletonAdCard from '@/components/cards/SkeletonAdCard.vue';
import Footer from '@/components/structural/Footer.vue';
import InfoSection from '@/components/structural/InfoSection.vue';
import SearchBox from '@/components/structural/SearchBox.vue';
import HeadingSection from '@/components/structural/HeadingSection.vue';
import { mapState } from 'vuex';
import { computed, reactive } from 'vue';
import { useHead } from '@unhead/vue';

export default {
    components: {
        SearchBox,
        HeadingSection,
        BrandCard,
        AdCard,
        SectionCard,
        InfoSection,
        SkeletonAdCard,
        Footer,
    },
    data() {
        return {
            loaded: false,
            loadSwitch: true,
            brands: [],
            modelSections: [
                {
                    makeSlug: 'volkswagen',
                    modelSlug: 'golf',
                    make: 'Volkswagen',
                    model: 'Golf',
                },
                {
                    makeSlug: 'mazda',
                    modelSlug: 'cx-5',
                    make: 'Mazda',
                    model: 'CX-5',
                },
                {
                    makeSlug: 'toyota',
                    modelSlug: 'corolla',
                    make: 'Toyota',
                    model: 'Corolla',
                },
                {
                    makeSlug: 'ford',
                    modelSlug: 'focus',
                    make: 'Ford',
                    model: 'Focus',
                },
                {
                    makeSlug: 'nissan',
                    modelSlug: 'qashqai',
                    make: 'Nissan',
                    model: 'Qashqai',
                },
                {
                    makeSlug: 'audi',
                    modelSlug: 'a3',
                    make: 'Audi',
                    model: 'A3',
                },
                {
                    makeSlug: 'honda',
                    modelSlug: 'civic',
                    make: 'Honda',
                    model: 'Civic',
                },
                {
                    makeSlug: 'peugeot',
                    modelSlug: 'partner',
                    make: 'Peugeot',
                    model: 'Partner',
                },
                {
                    makeSlug: 'bmw',
                    modelSlug: 'serie-3',
                    make: 'BMW',
                    model: 'Serie 3',
                },
                {
                    makeSlug: 'citroën',
                    modelSlug: 'c4',
                    make: 'Citroën',
                    model: 'C4',
                },
                {
                    makeSlug: 'volkswagen',
                    modelSlug: 't-roc',
                    make: 'Volkswagen',
                    model: 'T-Roc',
                },
                {
                    makeSlug: 'mercedes-benz',
                    modelSlug: 'clase-c',
                    make: 'Mercedes-Benz',
                    model: 'Clase C',
                },
                {
                    makeSlug: 'seat',
                    modelSlug: 'leon',
                    make: 'Seat',
                    model: 'León',
                },
                {
                    makeSlug: 'audi',
                    modelSlug: 'a4',
                    make: 'Audi',
                    model: 'A4',
                },
                {
                    makeSlug: 'bmw',
                    modelSlug: 'serie-5',
                    make: 'BMW',
                    model: 'Serie 5',
                },
                {
                    makeSlug: 'mercedes-benz',
                    modelSlug: 'clase-e',
                    make: 'Mercedes-Benz',
                    model: 'Clase E',
                },
                {
                    makeSlug: 'renault',
                    modelSlug: 'mégane',
                    make: 'Renault',
                    model: 'Mégane',
                },
                {
                    makeSlug: 'seat',
                    modelSlug: 'ibiza',
                    make: 'Seat',
                    model: 'Ibiza',
                },
                {
                    makeSlug: 'citroën',
                    modelSlug: 'berlingo',
                    make: 'Citroën',
                    model: 'Berlingo',
                },
                {
                    makeSlug: 'ford',
                    modelSlug: 'transit',
                    make: 'Ford',
                    model: 'Transit',
                },
                {
                    makeSlug: 'toyota',
                    modelSlug: 'land-cruiser',
                    make: 'Toyota',
                    model: 'Land Cruiser',
                },
            ],
            scroll: {
                prevPosition: 0,
                coords: [Math.round(0), Math.round(0)],
            },
        };
    },
    setup() {
        const siteData = reactive({
            title: 'AlertaCoches | Coches de segunda mano - Busca anuncios en diferentes portales de compraventa',
            description:
                'Busca y encuentra anuncios en los principales portales de compra-venta de España. Podrás encontrar coches de segunda mano de tus marcas favoritas: Volkswagen, Audi, Mercedes, Citroën, Peugeot... Con AlertaCoches, buscar un coche bueno, bonito y barato, ¡es pan comido!',
        });

        useHead({
            title: computed(() => siteData.title),
            meta: [{ name: 'description', content: computed(() => siteData.description) }],
        });
    },
    computed: {
        ...mapState(['ads', 'user']),
    },
    methods: {
        async initData() {
            this.brands = await getFilterValues(1);
        },
        scrollTo(element = 'heading-picture', behaviour = 'smooth') {
            let elmnt = document.getElementById(element);
            if (elmnt) {
                elmnt.scrollIntoView({
                    behavior: behaviour,
                    block: 'start',
                    inline: 'start',
                });
            }
        },
        async _scrollListener() {
            if (this.ads.items && !this.ads.initialSearch) {
                const resultsArea = document.getElementById('results_area');
                const elmnt = document.getElementById('heading-logo');
                const actionButton = document.getElementById('return_to_search_button');

                if (resultsArea) {
                    const THRESHOLD = window.innerHeight - 300;
                    var footerHeight = document.getElementById('footer').clientHeight;
                    var resultsAreaClientRect = resultsArea.getBoundingClientRect();
                    if (Math.round(resultsAreaClientRect.bottom) - footerHeight < THRESHOLD) {
                        if (this.loadSwitch) {
                            await this.$refs.HeadingSection.$refs.SearchBox.loadMore();
                            this.loadSwitch = false;
                            this.sleep(500);
                        }
                    } else {
                        this.loadSwitch = true;
                    }
                }

                if (elmnt) {
                    let clientRect = elmnt.getBoundingClientRect();
                    this.scroll.coords = [Math.round(clientRect.top), Math.round(clientRect.left)];
                    if (this.scroll.prevPosition > clientRect.top + 120) {
                        actionButton.classList.remove('hidden');
                        this.scroll.prevPosition = clientRect.top;
                    } else if (this.scroll.prevPosition < clientRect.top - 80) {
                        actionButton.classList.add('hidden');
                        this.scroll.prevPosition = clientRect.top;
                    }
                }
            }
        },
        sleep(ms) {
            return new Promise((resolve) => setTimeout(resolve, ms));
        },
        isFavourite(ad) {
            return this.user.favourites.includes(ad.id);
        },
    },
    mounted() {
        this.initData();
        window.addEventListener('scroll', this._scrollListener);
    },
    unmounted() {
        window.removeEventListener('scroll', this._scrollListener);
    },
};
</script>
<style lang="scss">
@import '@/styles/_variables.scss';
@import '@/styles/_mixins.scss';
.navbar {
    @include box-shadow;
    .navbar-brand {
        img {
            width: 45px;
            height: 45px;
        }
        span {
            margin-left: 10px;
            font-size: 0.8em;
        }
    }
}
.search-invite {
    position: fixed;
    float: right;
    background-color: $white;
    text-align: right;
    color: $gray-9;
    right: 0;
    margin-right: 20px;
    height: auto;
    width: auto;
    padding: 10px;
    @include box-shadow;
    border-radius: $border-radius;
    span {
        margin-top: 5px;
        font-size: 1.9em;
        line-height: 20px;
    }
}
.landing-picture {
    color: $gray-2;
    text-align: left;
    font-size: 1.3em;
    h2 {
        font-size: 1em;
    }
    svg {
        height: auto;
        width: 100%;
    }
}

p.unimportant-title {
    font-weight: bold;
    font-size: 1.5em;
}

hr {
    background-color: $gray-9;
}
h1,
h2,
h3,
h4,
h5 {
    text-align: left;
}
.results-area {
    h2 {
        font-size: 1em;
        font-weight: bold;
        margin-bottom: 20px;
    }
    p {
        text-align: left;
        font-size: 0.85em;
        margin-top: 0px;
        margin-bottom: 10px;
    }
}
.source {
    font-size: 0.8em;
    color: $secondary-2;
    h3 {
        font-size: 1.5em;
        text-align: center;
        font-weight: bold;
    }
    .number {
        text-align: center;
        font-size: 4.4em;
        font-weight: bold;
        color: $tertiary;
    }
}
.anuncios {
    font-size: 0.8em;
    color: $secondary-2;
    h3 {
        color: $secondary-2;
        font-size: 1.5em;
        text-align: center;
        font-weight: bold;
    }
    .number {
        text-align: center;
        font-size: 4.4em;
        font-weight: bold;
        color: $tertiary;
    }
}
.action-button {
    position: relative;
    float: left;
    width: 48%;
    padding: 15px;
    border: 0;
    border-radius: $border-radius;
    span {
        margin-right: 10px;
    }
}
.floating-action-button {
    position: fixed;
    z-index: 1000;
    width: 60px !important;
    height: 60px !important;
    bottom: 80px;
    right: 20px;
    -webkit-transition: all 0.7s 0s ease;
    -moz-transition: all 0.7s 0s ease;
    -o-transition: all 0.7s 0s ease;
    transition: all 0.7s 0s ease;
    border-radius: $border-radius-deep !important;
}
.hidden {
    bottom: -130px;
    -webkit-transition: all 0.7s 0s ease;
    -moz-transition: all 0.7s 0s ease;
    -o-transition: all 0.7s 0s ease;
    transition: all 0.7s 0s ease;
}
.return-to-search {
    font-size: 0.8em !important;
    border: 1px dashed $gray-10;
    background-color: transparent;
    color: $gray-0;
    text-decoration: none;
}
.load-more {
    font-size: 1em !important;
    background-color: $secondary-4;
    color: $white;
}
.other-model {
    background-color: $secondary-3;
    background-image: linear-gradient(to bottom right, $primary-5, $secondary-4);
    color: $white;
    div {
        text-align: left;
        #other_models_links div {
            &:hover {
                border-radius: $border-radius;
                background-color: $secondary-4;
                -webkit-transition: all 0.4s 0s ease;
                -moz-transition: all 0.4s 0s ease;
                -o-transition: all 0.4s 0s ease;
                transition: all 0.4s 0s ease;
                text-overflow: ellipsis;
            }
            &:hover > img {
                transform: rotate(360deg);
                -webkit-transition: all 0.4s 0s ease;
                -moz-transition: all 0.4s 0s ease;
                -o-transition: all 0.4s 0s ease;
                transition: all 0.4s 0s ease;
            }
            a {
                display: block;
                border-bottom: 1px $primary solid;
                padding: 5px;
                text-decoration: none;
                color: $white;
                border-bottom: 1px $primary solid;
                img {
                    float: right;
                    right: 0;
                }
                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
}
</style>
