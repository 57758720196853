<template v-if="this.ad && this.carfaxUrl">
    <div class="col-12 col-md-6 col-xl-6 p-1 mt-4" id="informe_carfax">
        <div class="embeddedCarfaxService">
            <h3>Informe del estado del coche</h3>
            <p>
                ¡Compra tu próximo coche con confianza! Descubre la historia completa del vehículo con el informe de
                Carfax. Evita sorpresas desagradables y toma decisiones informadas. ¡Obtén tu informe ahora para una
                compra segura y sin riesgos!
            </p>
            <div class="action">
                <button @click="this.showVinPlateNumberModal = !this.showVinPlateNumberModal" class="w-100">
                    <div class="link">
                        <span>VER EL HISTORIAL</span>
                    </div>
                </button>
                <v-dialog
                    id="vin-platenumber-modal"
                    class="align-center justify-center vin-number-plate-overlay"
                    scrollable
                    transition="dialog-bottom-transition"
                    :model-value="this.showVinPlateNumberModal"
                >
                    <v-toolbar density="compact" color="white">
                        <v-toolbar-title class="top-title ml-5">Introduce matrícula o bastidor</v-toolbar-title>
                        <v-btn icon @click="this.showVinPlateNumberModal = !this.showVinPlateNumberModal">
                            <v-icon size="small">mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <div class="form-container">
                        <div class="row pt-0">
                            <div class="logo-image">
                                <img
                                    src="https://www.carfax.eu/static/images/logo/logo-dark-mode.svg"
                                    alt="Genera un informe con el historial del coche"
                                />
                            </div>
                            <p>
                                <b>Estás a unos pocos clics de obtener el informe de Carfax.</b>
                                <br />
                                Por favor, indica la matrícula o el bastidor del vehículo que te interesa.
                            </p>
                        </div>
                        <div class="pop-up-image">
                            <img
                                src="@/assets/images/vin-fox-looking-down.png"
                                alt="Busca con Carfax el historial del coche que te interesa en alertacoches.es"
                            />
                        </div>
                        <v-form v-model="isVinOrNumberPlateValid">
                            <v-text-field
                                v-model="vinPlateNumber"
                                :rules="vinPlateNumberRules"
                                label="Matrícula o bastidor"
                                @update:modelValue="refreshCarfaxUrl"
                                theme="light"
                                variant="solo"
                                class="mb-2"
                                clearable
                                autofocus
                            ></v-text-field>
                        </v-form>

                        <div class="overlay-footer">
                            <div class="container col-12 col-md-6 login-components">
                                <a
                                    target="_blank"
                                    :href="this.serviceUrl"
                                    @click="registerCollabAdClick()"
                                    v-if="isVinOrNumberPlateValid"
                                >
                                    <v-btn block class="mt-3 btn btn-primary">Obtener informe</v-btn>
                                </a>
                                <a v-else>
                                    <v-btn :disabled="!isVinOrNumberPlateValid" block class="mt-3 btn">
                                        Obtener informe
                                    </v-btn>
                                </a>
                            </div>
                        </div>
                    </div>
                </v-dialog>
            </div>
            <img
                loading="lazy"
                src="https://www.carfax.eu/static/images/logo/logo-dark-mode.svg"
                alt="Genera un informe con el historial del coche"
            />
        </div>
    </div>
</template>
<script>
import { validate } from '@/infrastructure/services/validator';
export default {
    props: ['ad', 'makeList', 'modelList', 'vehicleIdentifier'],
    data() {
        return {
            carfaxUrl: null,
            make: null,
            model: null,
            province: null,
            showVinPlateNumberModal: false,
            vinPlateNumber: this.vehicleIdentifier || '',
            vinPlateNumberRules: validate.vinPlateNumber,
            isVinOrNumberPlateValid: false,
            serviceUrl: null,
        };
    },
    methods: {
        registerCollabAdClick() {
            gtag('event', 'ac_click_ad', {
                type: 'report',
                customerName: 'Carfax',
                contentId: 'report',
                make: this.make,
                model: this.model,
                province: this.ad.location.province,
            });
        },
        async refreshCarfaxUrl() {
            let carfaxUrl = new URL('https://www.carfax.eu/es/partner/alertacoches');
            await this.setFunctionalParameters(carfaxUrl, this.ad);
            this.setTrackingParameters(carfaxUrl);

            this.serviceUrl = encodeURI(carfaxUrl.href);
        },
        async setFunctionalParameters(url, ad) {
            this.make = await this.getShortDesc(this.ad.make, this.makeList);
            this.model = await this.getShortDesc(this.ad.model, this.modelList);

            url.searchParams.append('vin', this.vinPlateNumber);
            url.searchParams.append('adurl', `https://www.alertacoches.es${this.$route.fullPath}`);
            url.searchParams.append('make', this.make);
            url.searchParams.append('model', this.model);
        },
        async setTrackingParameters(url) {
            url.hash =
                '#utm_source=alertacoches.es&utm_medium=website&utm_content=website_link&utm_campaign=classifieds_integrations';
        },
        async getShortDesc(id, haystack) {
            if (!id) {
                return null;
            }
            if (!haystack) {
                return null;
            }

            let obj = haystack.find((o) => o.value_id === id);

            if (!obj) {
                return null;
            }

            return obj.short_desc;
        },
    },
    mounted() {
        gtag('event', 'ac_display_ad', {
            type: 'report',
            customerName: 'Carfax',
            contentId: 'report',
            make: this.make,
            model: this.model,
            province: this.ad.location.province,
        });
        this.refreshCarfaxUrl();
    },
};
</script>
<style lang="scss">
@import '@/styles/_variables.scss';
@import '@/styles/_mixins.scss';

.embeddedCarfaxService {
    min-height: 140px;
    background-color: $white !important;
    border-radius: $border-radius-deep;
    font-weight: 800;
    padding: 15px;
    border: 2px dashed $gray-15;
    h3 {
        font-size: 0.9rem;
        margin-bottom: 0px;
        font-weight: bold;
        margin-bottom: 5px;
    }
    p {
        font-size: 0.75rem;
        margin: 15px 0px;
    }
    div {
        position: relative;
        margin: 10px auto;
    }
    .action {
        margin: 15px auto;
        text-align: center;
        width: 90%;
        .link {
            width: 100%;
            padding: 10px 0px;
            background-color: #444;
            border-radius: $border-radius-deep;
        }
        span {
            font-family: $secondary-font;
            text-transform: uppercase;
            color: $white;
            font-size: 0.7rem;
            height: 60px;
            width: 100%;
            margin-bottom: 0px;
            font-weight: 800;
        }
    }
    img {
        width: 100%;
        height: 20px;
        margin: 10px auto;
    }
    .price-tag {
        position: relative;
        float: right;
        top: 0px;
        right: 0px;
        background-color: $gray-15;
        border-radius: $border-radius-deep;
        padding: 5px 10px;
        font-size: 1rem;
        font-weight: bold;
        color: $secondary;
    }
}
.vin-number-plate-overlay {
    .form-container {
        padding: 20px;
        overflow-y: auto;
        overflow-x: hidden;
        .pop-up-image {
            display: flex;
            width: 100%;
            img {
                width: 100px;
                height: auto;
                margin: 0px auto -5px;
            }
        }
        .logo-image {
            display: flex;
            width: 100%;
            margin: 10px auto;
            justify-content: center;
            img {
                margin: 0px auto 20px;
                width: 150px;
                height: auto;
            }
        }
    }
    .v-overlay__content {
        position: absolute;
        width: 100%;
        font-family: $main-font;
        max-width: 100%;
        max-height: 95%;
        min-height: 70%;
        bottom: 0;
        margin: 0 !important;
        background-color: $white;
        border-top-left-radius: $border-radius-deep;
        border-top-right-radius: $border-radius-deep;
        font-size: 0.9rem;
        .v-toolbar {
            border-top-left-radius: $border-radius-deep;
            border-top-right-radius: $border-radius-deep;
        }
        .top-title {
            color: $gray-0;
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 0.2px;
        }
        .overlay-footer {
            background-color: $white;
            width: 100%;
            padding: 0px 0px 25px 0px;
            position: fixed;
            bottom: 0;
            left: 0;
        }
    }
    @media (min-width: $breakpoint-md) {
        .v-overlay__content {
            position: relative;
            border-radius: $border-radius-deep;
            max-width: 800px;
            min-height: 60%;
            .overlay-footer {
                border-radius: $border-radius-deep;
            }
        }
    }
}
</style>
